import { useEffect, useState } from 'react'

type SearchDataObject = {
  [key: string]: string | number
}

type UseTableSearchProps = {
  searchValue: string
  // The retrieve parameter is used to fetch the data inside the hook so that the searchValue can be used to filter that data.
  retrieve: () => Promise<{ data: SearchDataObject[] }>
}

type StringOrStringArray = string | string[]

const useTableSearch = (props: UseTableSearchProps) => {
  const { searchValue, retrieve } = props
  const [filteredData, setFilteredData] = useState<SearchDataObject[]>([])
  const [origData, setOrigData] = useState<SearchDataObject[]>([]) // The original data fetched using retrieve method and that has been passed on to the hook from the Transports component.
  const [searchIndex, setSearchIndex] = useState<SearchDataObject[]>([]) // The searchIndex used to filter the data whenever searchValue changes.
  const [loading, setLoading] = useState(true)

  /**
   * The 1st React hook, is used to perform 2 major tasks:
   *
   * 1. To fetch the original data and store it in our local state.
   * 2. To generate a Search Index using the data, which can later be used to perform the actual filtering, and to also store it in our local state.
   *
   * The hook gets called only when the retrieve function changes. Normally it should only get called when the component is mounted.
   */

  useEffect(() => {
    setLoading(true)
    const fetchAllColumnStringData = (data: SearchDataObject | StringOrStringArray, allValues?: string[]): string[] => {
      let values = allValues
      if (!values) values = []
      if (typeof data !== 'object') return values

      // If data is string array, loop through it and add the values to the allValues result
      if (Array.isArray(data)) {
        data.forEach((arrayItem: string) => {
          values?.push(arrayItem)
        })
        return values
      }
      Object.entries(data).forEach(([key]) => {
        if (typeof data[key] === 'object' && data[key]) {
          // If data[key] item is object, recursively run function to continue fetching string values inside of said object
          fetchAllColumnStringData(data[key] as StringOrStringArray, values)
        } else {
          // Add the data value to the allValues array
          values?.push(`${data[key]} `)
        }
      })
      return values
    }

    const fetchData = async () => {
      const { data } = await retrieve()
      setOrigData(data)
      setFilteredData(data)
      const searchInd: SearchDataObject[] = data.map((row: SearchDataObject) => {
        const allValues = fetchAllColumnStringData(row)
        return { allValues: allValues.toString() }
      })
      setSearchIndex(searchInd)
      if (data) setLoading(false)
    }
    fetchData()
  }, [retrieve])

  /**
   * The 2nd React hook, is used for the filtering part.
   * Notice that we have the searchValue as a dependency for our hook. This hook will be called every-time the user input i.e. searchValue changes.
   */

  useEffect(() => {
    if (searchValue) {
      const reqData: (SearchDataObject | null)[] = searchIndex.map((row: SearchDataObject, index: number) => {
        if (row.allValues.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) return origData[index]
        return null
      })
      const sortedData = reqData.filter((row) => {
        if (row) return true
        return false
      })
      setFilteredData(sortedData as SearchDataObject[])
    } else setFilteredData(origData)
  }, [searchValue, origData, searchIndex])

  return { filteredData, isLoading: loading }
}
export default useTableSearch
