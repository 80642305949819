import { ReservationGroups, Reservation } from '../types/Reservation'
import { Location } from '../types/station.types'

export const isSuccessfulResponse = (status: number): boolean => status >= 200 && status < 300

export const sortLocations = (locations: Location[]) => {
  const sortedLocations = locations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  return sortedLocations
}

export const remapReservationData = (data: ReservationGroups) => {
  const categories = ['active', 'upcoming', 'historical'] as const
  const remappedData: Reservation[] = []

  categories.forEach((category) => {
    if (data[category]) {
      data[category].forEach((reservation: Reservation) => {
        const remappedReservation = { ...reservation, reservationStatus: category }

        if (category === 'historical' && reservation.status === 'CREATED') {
          remappedReservation.status = 'PASSED'
        }

        remappedData.push(remappedReservation)
      })
    }
  })

  return remappedData
}
