import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Dispatch, MouseEvent, SetStateAction } from 'react'

type AlertDialogProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  title: string
  titleOfDenyButton?: string
  titleOfConfirmButton?: string
  contentText: string
  handleConfirmButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export default function AlertDialog(props: AlertDialogProps) {
  const { open, setOpen, title, contentText, titleOfDenyButton, titleOfConfirmButton, handleConfirmButtonClick } = props

  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.value === '1') {
      handleConfirmButtonClick(event)
    }
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button value={0} onClick={handleClose}>
            {titleOfDenyButton}
          </Button>
          <Button value={1} onClick={handleClose} autoFocus>
            {titleOfConfirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

AlertDialog.defaultProps = { titleOfDenyButton: 'No', titleOfConfirmButton: 'Yes' }
