import React, { useMemo } from 'react'
import DataTable, { TableColumn, TableProps } from 'react-data-table-component'

interface Props<T> extends TableProps<T> {
  data: T[]
  columns: TableColumn<T>[]
  columnsToHide: string[]
  selectableRows?: boolean
}

export default function Table<T>({ data, columns, columnsToHide, selectableRows, ...props }: Props<T>) {
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  }

  const newColumns = useMemo(() => {
    const modifiedColumns: TableColumn<T>[] = columns.map((column) => {
      const modifiedColumn = column

      if (columnsToHide.includes(modifiedColumn.name as string)) {
        modifiedColumn.omit = true
      } else {
        modifiedColumn.omit = false
      }
      modifiedColumn.reorder = true
      return modifiedColumn
    })
    return modifiedColumns
  }, [columns, columnsToHide])

  return (
    <DataTable
      data={data}
      columns={newColumns}
      responsive
      highlightOnHover
      selectableRows={selectableRows}
      selectableRowsHighlight
      pointerOnHover={selectableRows}
      pagination
      paginationRowsPerPageOptions={[10, 20, 30, 40]}
      customStyles={customStyles}
      {...props}
    />
  )
}
