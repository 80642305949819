enum ReservationStatus {
  BOOKED = 'BOOKED',
  BOOKED_PASSED = 'BOOKED (PASSED)',
  PASSED = 'PASSED',
  ACTIVE = 'ACTIVE',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
}

enum ReservationStatusText {
  BOOKED = 'Booked',
  BOOKED_PASSED = 'Booked (Passed)',
  PASSED = 'Passed',
  ACTIVE = 'Active',
  CANCELLED_BY_CUSTOMER = 'Cancelled by user',
  CANCELLED_BY_ADMIN = 'Cancelled by admin',
  CANCELLED = 'Cancelled',
  CREATED = 'Created',
}

export { ReservationStatus, ReservationStatusText }
