import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Column, TableVariant } from '../../types/table.types'

export interface TableSettings {
  page: number
  pageSize: number
  columnOrder: string[]
  hiddenColumns: string[]
  filter: string
  sorting: { column: Column; order: 'asc' | 'desc' }
}

export interface TableSettingsState {
  transportOffers: TableSettings
  reservations: TableSettings
  stations: TableSettings
  adminUsers: TableSettings
}

export const initialTableSettings: TableSettings = {
  page: 1,
  pageSize: 10,
  columnOrder: [],
  hiddenColumns: [],
  filter: '',
  sorting: { column: { id: '' }, order: 'asc' },
}

export const initialState: TableSettingsState = {
  transportOffers: initialTableSettings,
  reservations: initialTableSettings,
  stations: initialTableSettings,
  adminUsers: initialTableSettings,
}

export const tableSettingsSlice = createSlice({
  name: 'tableSettings',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<{ currentPage: number; tableVariant: TableVariant }>) => {
      const { currentPage, tableVariant } = action.payload
      state[tableVariant].page = currentPage
    },
    setPageSize: (state, action: PayloadAction<{ pageSize: number; tableVariant: TableVariant }>) => {
      const { pageSize, tableVariant } = action.payload
      state[tableVariant].pageSize = pageSize
    },
    setColumnOrder: (state, action: PayloadAction<{ columnOrder: string[]; tableVariant: TableVariant }>) => {
      const { tableVariant, columnOrder } = action.payload
      state[tableVariant].columnOrder = columnOrder
    },
    setHiddenColumns: (state, action: PayloadAction<{ hiddenColumns: string[]; tableVariant: TableVariant }>) => {
      const { tableVariant, hiddenColumns } = action.payload
      state[tableVariant].hiddenColumns = hiddenColumns
    },
    setTableFiltering: (state, action: PayloadAction<{ tableFilter: string; tableVariant: TableVariant }>) => {
      const { tableVariant, tableFilter } = action.payload
      state[tableVariant].filter = tableFilter
    },
    setSorting: (
      state,
      action: PayloadAction<{
        sort: { order: 'asc' | 'desc'; column: Column }
        tableVariant: TableVariant
      }>,
    ) => {
      const { tableVariant, sort } = action.payload
      state[tableVariant].sorting = sort
    },
  },
})

export const { setPage, setPageSize, setColumnOrder, setHiddenColumns, setTableFiltering, setSorting } =
  tableSettingsSlice.actions

export const tableSettingsReducer = tableSettingsSlice.reducer
