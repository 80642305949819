import { styled } from '@mui/material'
import { ReactNode } from 'react'
import { TableColumn } from 'react-data-table-component'

import { Reservation } from '../types/Reservation'
import { ReservationStatus, ReservationStatusText } from '../types/ReservationStatus'
import { getDatePlusOffset } from '../utils'

const StyledColumnDiv = styled('div')(() => ({
  color: '#000000',
  fontSize: '14px',
  fontWeight: '400',
  textTransform: 'capitalize',
}))

const StyledColumnDivSmall = styled('div')(() => ({
  color: '#00000099',
  fontSize: '12px',
}))

const setCellResNrStyle = (reservationId: string): ReactNode => (
  <StyledColumnDiv
    data-tag="allowRowEvents"
    style={{
      color: '#3A81B3',
      fontWeight: '600',
    }}
  >
    {reservationId}
  </StyledColumnDiv>
)

const setCellPickupLocationStyle = (pickupLocationName: string, pickupLocationTracCode: string): ReactNode => (
  <StyledColumnDiv data-tag="allowRowEvents">
    {pickupLocationName}
    <StyledColumnDivSmall>{pickupLocationTracCode}</StyledColumnDivSmall>
  </StyledColumnDiv>
)

const setCellDateStyle = (date: string): ReactNode => (
  <StyledColumnDiv data-tag="allowRowEvents">{getDatePlusOffset(date).toFormat("yyyy-MM-dd' 'HH:mm")}</StyledColumnDiv>
)

const setCellCustomerStyle = (firstName: string, lastName: string): ReactNode => (
  <StyledColumnDiv data-tag="allowRowEvents">
    {firstName} {lastName}
  </StyledColumnDiv>
)

const setCellLicPlateStyle = (licPlate: string): ReactNode => (
  <StyledColumnDiv data-tag="allowRowEvents">{licPlate.toUpperCase()}</StyledColumnDiv>
)

const setCellStatusStyle = (status: string): ReactNode => {
  switch (status.toUpperCase()) {
    case ReservationStatus.CREATED:
      return <StyledColumnDiv data-tag="allowRowEvents">{ReservationStatusText.BOOKED}</StyledColumnDiv>
    case ReservationStatus.CANCELLED_BY_CUSTOMER:
      return <StyledColumnDiv data-tag="allowRowEvents">{ReservationStatusText.CANCELLED_BY_CUSTOMER}</StyledColumnDiv>
    case ReservationStatus.PASSED:
      return <StyledColumnDiv data-tag="allowRowEvents">{ReservationStatusText.BOOKED_PASSED}</StyledColumnDiv>
    case ReservationStatus.CANCELLED_BY_ADMIN:
      return <StyledColumnDiv data-tag="allowRowEvents">{ReservationStatusText.CANCELLED_BY_ADMIN}</StyledColumnDiv>
    default:
      return <StyledColumnDiv data-tag="allowRowEvents">{status.toLowerCase()}</StyledColumnDiv>
  }
}

export const reservationColumns: TableColumn<Reservation>[] = [
  {
    name: 'Res nr',
    selector: (row) => row.reservationId ?? '',
    cell: (row) => setCellResNrStyle(row.reservationId ?? ''),
    sortable: true,
  },
  {
    name: 'From',
    selector: (row) => `${row.pickupLocation?.name ?? ''} ${row.pickupLocation?.tracCode ?? ''}`,
    cell: (row) => setCellPickupLocationStyle(row.pickupLocation?.name ?? '', row.pickupLocation?.tracCode ?? ''),
    sortable: true,
  },
  {
    name: 'Pickup time',
    selector: (row) => row.pickupDate ?? '',
    cell: (row) => setCellDateStyle(row.pickupDate ?? ''),
    sortable: true,
  },
  {
    name: 'Customer',
    selector: (row) => `${row.driver?.firstName ?? ''} ${row.driver?.lastName ?? ''}`,
    cell: (row) => setCellCustomerStyle(row.driver?.firstName ?? '', row.driver?.lastName ?? ''),
    sortable: true,
  },
  {
    name: 'Lic plate',
    selector: (row) => row.car?.licenseNumber ?? '',
    cell: (row) => setCellLicPlateStyle(row.car?.licenseNumber ?? ''),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row.status ?? '',
    sortable: true,
    cell: (row) => setCellStatusStyle(row.status?.toLocaleLowerCase() ?? ''),
  },
  {
    name: 'Created',
    selector: (row) => row.createdDate ?? '',
    cell: (row) => setCellDateStyle(row.createdDate ?? ''),
    sortable: true,
  },
  {
    name: 'Return',
    selector: (row) => row.returnDate ?? '',
    cell: (row) => setCellDateStyle(row.returnDate ?? ''),
    sortable: true,
  },
]

export default reservationColumns
