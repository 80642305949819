import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getUsers } from '../../api/user'
import { User, UsersRequest } from '../../types/user.types'

export interface UsersTableSettings {
  page: number
  pageCount: number
  pageSize: number
  totalSize: number | undefined
  columnOrder: string[]
  hiddenColumns: string[]
  statusFilter: string
  freeText: string
  sortBy: string
  sortDirection: 'asc' | 'desc'
}

export interface UsersState {
  users: User[] | null
  loadingUsers: 'idle' | 'pending' | 'succeeded' | 'failed'
  tableSettings: UsersTableSettings
}

export const initialUsersState: UsersState = {
  users: null,
  loadingUsers: 'idle',
  tableSettings: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    totalSize: undefined,
    columnOrder: [],
    hiddenColumns: [],
    statusFilter: '',
    freeText: '',
    sortBy: '',
    sortDirection: 'asc',
  },
}

const mapSettingsToRequest = (settings: UsersTableSettings): UsersRequest => ({
  page: settings.page,
  pageSize: settings.pageSize,
  sortBy: settings.sortBy || null,
  sortDirection: settings.sortDirection === 'asc' ? 'ASC' : 'DESC',
  freeText: settings.freeText,
  statusFilter: settings.statusFilter || null,
})

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (settings: UsersTableSettings, thunkAPI) => {
  try {
    const response = await getUsers(mapSettingsToRequest(settings))
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    setLoadingUsers: (state, action: PayloadAction<'idle' | 'pending' | 'succeeded' | 'failed'>) => {
      state.loadingUsers = action.payload
    },
    setUsersColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.columnOrder = action.payload
    },
    setUsersHiddenColumns: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.hiddenColumns = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loadingUsers = 'pending'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { items, query, ...rest } = action.payload

        state.users = items
        state.tableSettings = {
          ...state.tableSettings,
          statusFilter: query.statusFilter ?? '',
          freeText: query.freeText,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection === 'ASC' ? 'asc' : 'desc',
          ...rest,
        }
        state.loadingUsers = 'succeeded'
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loadingUsers = 'failed'
      })
  },
})

export const { setLoadingUsers, setUsersColumnOrder, setUsersHiddenColumns } = usersSlice.actions

export default usersSlice.reducer
