import { Middleware } from '@reduxjs/toolkit'

import { TableSettings, TableSettingsState, initialTableSettings } from '../../features/table/tableSettingsSlice'
import { UsersTableSettings, initialUsersState } from '../../features/users/usersSlice'

export const setLocalStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)

  if (action.type.startsWith('tableSettings/')) {
    const { tableVariant } = action.payload
    const tableKey = `${tableVariant}Table`
    const tableSettings = store.getState().tableSettings[tableVariant]
    localStorage.setItem(tableKey, JSON.stringify(tableSettings))
  }
  if (action.type.startsWith('users/')) {
    const { tableSettings } = store.getState().users
    localStorage.setItem('freeriderUsersTable', JSON.stringify(tableSettings))
  }
  return result
}

const getTableSettings = (key: string, defaultSettings: TableSettings): TableSettings => {
  const serializedState = localStorage.getItem(key) || '{}'
  const parsedState = JSON.parse(serializedState)

  return { ...defaultSettings, ...parsedState }
}

const getUserTableSettings = (defaultSettings: UsersTableSettings): UsersTableSettings => {
  const serializedState = localStorage.getItem('freeriderUsersTable') || '{}'
  const parsedState = JSON.parse(serializedState)

  return { ...defaultSettings, ...parsedState }
}

export const getTableSettingsStateFromLocalStorage = (): TableSettingsState => ({
  transportOffers: getTableSettings('transportOffersTable', initialTableSettings),
  reservations: getTableSettings('reservationsTable', initialTableSettings),
  stations: getTableSettings('stationsTable', initialTableSettings),
  adminUsers: getTableSettings('adminUsersTable', initialTableSettings),
})

export const getUsersStateFromLocalStorage = () => ({
  users: initialUsersState.users,
  loadingUsers: initialUsersState.loadingUsers,
  tableSettings: getUserTableSettings(initialUsersState.tableSettings),
})
