export enum AdminAccessRole {
  ALL_ACCESS = 'ALL ACCESS',
  SUPER = 'SUPER',
  TRANSPORT = 'TRANSPORT',
  NO_ACCESS = 'NO ACCESS',
}

export enum UserStatus {
  ACTIVATED = 'ACTIVATED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
  SUSPENDED_BY_OLD_FREERIDER = 'SUSPENDED_BY_OLD_FREERIDER',
  WARNED = 'WARNED',
}

export enum UserColumn {
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  TELEPHONE = 'Telephone',
  E_MAIL = 'E-mail',
  SSN = 'Social sec nr',
  COUNTRY = 'Country',
  ACCOUNT_STATUS = 'Account status',
}

export type User = {
  id: number
  firstName: string
  lastName: string
  phoneNumber: string
  memberSince: string
  emailAddress: string
  userStatus: UserStatus
  socialSecurityNumber: string
  address: string
  secondAddress: string
  city: string
  postalCode: string
  countryCode: string | null
  iamId: string
  adminRole: AdminAccessRole | null
  driverLicenseIsoIssueCountry: string
  driverLicenseIssueDate: string | null
  driverLicenseDueDate: string | null
  numberOfFreerides: number
  cancelledFreerides: number
}

export type UsersRequest = {
  page: number
  pageSize: number
  sortBy: string | null
  sortDirection: 'ASC' | 'DESC'
  freeText: string
  statusFilter: string | null
}

export type UsersResponse = {
  items: User[]
  page: number
  pageSize: number
  pageCount: number
  totalSize: number
  query: {
    freeText: string
    statusFilter: string | null
    sortBy: string
    sortDirection: 'ASC' | 'DESC'
  }
}

export type UserUpdatedBy = {
  id: number
  iamId: string
  firstName: string
  lastName: string
}

export type AdminCreationRequest = Pick<User, 'emailAddress' | 'adminRole'>

export const defaultUser: User = {
  id: 0,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  memberSince: '',
  emailAddress: '',
  userStatus: UserStatus.PENDING,
  socialSecurityNumber: '',
  address: '',
  secondAddress: '',
  city: '',
  postalCode: '',
  countryCode: null,
  iamId: '',
  adminRole: null,
  numberOfFreerides: 0,
  cancelledFreerides: 0,
  driverLicenseIsoIssueCountry: '',
  driverLicenseIssueDate: null,
  driverLicenseDueDate: null,
}
