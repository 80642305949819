import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import {
  getTableSettingsStateFromLocalStorage,
  getUsersStateFromLocalStorage,
  setLocalStorageMiddleware,
} from './middleware/localStorageMiddleware'
import authReducer from './slices/authSlice'
import createTransportOfferReducer from './slices/createTransportOfferSlice'
import transferIsPendingReducer from './slices/transferIsPendingSlice'
import { transportOffersReducer } from './slices/transportOfferSlice'
import updateTransportOfferReducer from './slices/updateTransportOfferSlice'

import abuseAlertDialogReducer from '../features/abuse-dialog/abuseDialogSlice'
import adminsReducer from '../features/admin-users/adminUsersSlice'
import localeMiddleware from '../features/localization/detectLocaleMiddleware'
import localeSliceReducer from '../features/localization/localeSlice'
import locationsReducer from '../features/locations/locationSlice'
import reservationsReducer from '../features/reservations/reservationSlice'
import { tableSettingsReducer } from '../features/table/tableSettingsSlice'
import snackbarReducer from '../features/ui/snackbar/snackbarSlice'
import uiSliceReducer from '../features/ui/uiSlice'
import usersReducer from '../features/users/usersSlice'

const preloadedState = {
  tableSettings: getTableSettingsStateFromLocalStorage(),
  users: getUsersStateFromLocalStorage(),
}

const store = configureStore({
  reducer: {
    auth: authReducer,
    locations: locationsReducer,
    reservations: reservationsReducer,
    transportOffers: transportOffersReducer,
    transferIsPending: transferIsPendingReducer,
    abuseDialog: abuseAlertDialogReducer,
    createTransportOffer: createTransportOfferReducer,
    updateTransportOffer: updateTransportOfferReducer,
    snackbar: snackbarReducer,
    tableSettings: tableSettingsReducer,
    locale: localeSliceReducer,
    ui: uiSliceReducer,
    admins: adminsReducer,
    users: usersReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(setLocalStorageMiddleware, localeMiddleware),
})
store.dispatch({ type: '@@INIT' })

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>

export default store
