import { Box, Paper } from '@mui/material'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { useSelector } from 'react-redux'

import TableSearchField from './components/TableSearchField'
import TableToolBar from './components/TableToolBar'
import TableContent from './TableContent'
import { FilterItem } from '../../models/FilterItems'
import { fetchAllTransportOffers } from '../../store/slices/transportOfferSlice'
import { RootState, useAppDispatch } from '../../store/store'
import { Reservation } from '../../types/Reservation'
import { Location } from '../../types/station.types'
import { TableVariant, SearchObject, WithRowIds } from '../../types/table.types'
import { TransportOffer } from '../../types/transport.types'
import { User } from '../../types/user.types'
import useTableSearch from '../../utils/useTableSearch'
import { fetchAllAdmins } from '../admin-users/adminUsersSlice'
import { fetchAllLocations } from '../locations/locationSlice'
import { fetchAllReservations } from '../reservations/reservationSlice'

type Props<T> = {
  tableVariant: TableVariant
  tableColumns: TableColumn<T>[]
  filter: string
  filterItems: FilterItem[]
  filterColumn: string
  clickableRows?: boolean
  selectableRows?: boolean
  setTableFilter: Dispatch<SetStateAction<string>>
  setClickedRowId?: Dispatch<SetStateAction<number>>
  setClickedRowIamId?: Dispatch<SetStateAction<string>>
  deleteRowItems?: (ids: number[]) => Promise<void>
}

export default function TableContainer<T extends WithRowIds>({
  tableVariant,
  tableColumns,
  filter,
  filterItems,
  filterColumn,
  clickableRows,
  selectableRows,
  setTableFilter,
  setClickedRowId,
  setClickedRowIamId,
  deleteRowItems,
}: Props<T>) {
  const appDispatch = useAppDispatch()

  const [searchValue, setSearchValue] = useState('')
  const [selRows, setSelRows] = useState<Array<T & WithRowIds>>([])
  const [clearRows, setClearRows] = useState(false) // ?

  const { loadingLocations, locations } = useSelector((state: RootState) => state.locations)
  const { loadingReservations, reservations } = useSelector((state: RootState) => state.reservations)
  const { loadingAdmins, admins } = useSelector((state: RootState) => state.admins)
  const { loadingTransportOffers, transportOffers } = useSelector((state: RootState) => state.transportOffers)
  const { hiddenColumns } = useSelector((state: RootState) => state.tableSettings[tableVariant])

  useEffect(() => {
    if (loadingTransportOffers === 'idle' && tableVariant === 'transportOffers') {
      appDispatch(fetchAllTransportOffers())
    }
    if (loadingLocations === 'idle' && tableVariant === 'stations') {
      appDispatch(fetchAllLocations())
    }
    if (loadingReservations === 'idle' && tableVariant === 'reservations') {
      appDispatch(fetchAllReservations())
    }
    if (loadingAdmins === 'idle' && tableVariant === 'adminUsers') {
      appDispatch(fetchAllAdmins())
    }
  }, [appDispatch, loadingLocations, loadingTransportOffers, loadingReservations, loadingAdmins, tableVariant])

  const apiDataFetchers = useMemo(
    () => ({
      transportOffers: () => Promise.resolve(transportOffers),
      adminUsers: () => Promise.resolve(admins),
      stations: () => Promise.resolve(locations),
      reservations: () => Promise.resolve(reservations),
    }),
    [transportOffers, admins, locations, reservations],
  )

  const columNames = useMemo(
    () =>
      tableColumns
        .map((column) => column.name)
        .filter((name): name is string => typeof name === 'string')
        .sort(),
    [tableColumns],
  )

  const getTableData = useCallback(async () => {
    const fetchData = apiDataFetchers[tableVariant]
    let data = fetchData ? await fetchData() : []

    if (filter !== '') {
      const propertyName = filterColumn
      switch (tableVariant) {
        case 'transportOffers':
          data = (data as TransportOffer[]).filter(
            (item) => item[propertyName as keyof TransportOffer]?.toString().toLowerCase() === filter.toLowerCase(),
          )
          break
        case 'adminUsers':
          data = (data as User[]).filter(
            (item) => item[propertyName as keyof User]?.toString().toLowerCase().includes(filter.toLowerCase()),
          )
          break
        case 'stations':
          data = (data as Location[]).filter(
            (item) => item[propertyName as keyof Location]?.toString().toLowerCase() === filter.toLowerCase(),
          )
          break
        case 'reservations':
          data = (data as Reservation[]).filter(
            (item) => item[propertyName as keyof Reservation]?.toString().toLowerCase().includes(filter.toLowerCase()),
          )
          break
        default:
          break
      }
    }

    return { data: data as unknown as SearchObject[] }
  }, [apiDataFetchers, tableVariant, filter, filterColumn])

  const { filteredData, isLoading } = useTableSearch({
    searchValue,
    retrieve: getTableData,
  })

  const resFilteredData: T[] = filteredData as unknown as T[]

  const clearSelectedRows = () => {
    setSelRows([])
    setClearRows(!clearRows)
  }

  const deleteSelectedRowItems = async () => {
    if (deleteRowItems) {
      const itemsToDelete: number[] = selRows.map((obj) => obj.id)
      deleteRowItems(itemsToDelete)
      clearSelectedRows()
    }
  }

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" padding={2}>
        <TableSearchField onChange={setSearchValue} />
        <TableToolBar
          tableVariant={tableVariant}
          columNames={columNames}
          filterItems={filterItems}
          filter={filter}
          hiddenColumns={hiddenColumns}
          onFilterChange={setTableFilter}
        />
      </Box>
      <TableContent<T>
        tableVariant={tableVariant}
        tableData={resFilteredData}
        tableColumns={tableColumns}
        isLoading={isLoading}
        selRows={selRows}
        clearRows={clearRows}
        clickableRows={clickableRows}
        selectableRows={selectableRows}
        setClickedRowId={setClickedRowId}
        setClickedRowIamId={setClickedRowIamId}
        setSelRows={setSelRows}
        clearSelectedRows={clearSelectedRows}
        deleteSelectedRows={deleteSelectedRowItems}
      />
    </Paper>
  )
}
