import axios, { isAxiosError } from 'axios'

import { TransportResponseError, TransportOffer, TransportOfferRequest } from '../types/transport.types'
import { getResponseError } from '../utils'

const transportOffersEndpoint = process.env.REACT_APP_ENDPOINT_TRANSPORTOFFERS

export const getTransportOfferById = async (id: number): Promise<TransportOffer | null> => {
  try {
    const response = await axios.get<TransportOffer>(`${transportOffersEndpoint}${id}`)
    return response.data
  } catch (error) {
    return null
  }
}

export const getTransportOffers = async (): Promise<TransportOffer[]> => {
  try {
    const response = await axios.get<TransportOffer[]>(transportOffersEndpoint)
    return response.data
  } catch {
    return []
  }
}

export const createTransportOffer = async (
  transport: TransportOfferRequest,
): Promise<boolean | TransportResponseError> => {
  try {
    await axios.post(transportOffersEndpoint, transport)
    return true
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const errorResponse = getResponseError(error.response.data)
      if (errorResponse) {
        return errorResponse
      }
      return false
    }
    return false
  }
}

export const updateTransportOffer = async (transport: TransportOfferRequest): Promise<boolean> => {
  try {
    await axios.put(transportOffersEndpoint, transport)
    return true
  } catch (error) {
    return false
  }
}

export const deleteTransportOffers = async (transports: number[]): Promise<boolean | TransportResponseError> => {
  try {
    await axios.delete(transportOffersEndpoint, { data: transports })
    return true
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const errorResponse = getResponseError(error.response.data)
      if (errorResponse) {
        return errorResponse
      }
      return false
    }
    return false
  }
}
