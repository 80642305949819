import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getTransportOffers } from '../../api/transportOffer'

import { TransportOffer } from '../../types/transport.types'

export interface TransportOffersState {
  transportOffers: TransportOffer[]
  loadingTransportOffers: 'idle' | 'pending' | 'succeeded' | 'failed'
}
const initialState: TransportOffersState = {
  transportOffers: [],
  loadingTransportOffers: 'idle',
}

export const fetchAllTransportOffers = createAsyncThunk('transportOffers/fetchAll', async () => {
  try {
    const response = await getTransportOffers()
    return response
  } catch (err) {
    throw Error('failed to get locations')
  }
})

export const transportOffersSlice = createSlice({
  name: 'transportOffers',
  initialState,
  reducers: {
    setLoadingTransportOffers: (state, action: PayloadAction<'idle' | 'pending' | 'succeeded' | 'failed'>) => {
      state.loadingTransportOffers = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTransportOffers.pending, (state) => {
        state.loadingTransportOffers = 'pending'
      })
      .addCase(fetchAllTransportOffers.fulfilled, (state, action) => {
        state.transportOffers = action.payload
        state.loadingTransportOffers = 'succeeded'
      })
      .addCase(fetchAllTransportOffers.rejected, (state, action) => {
        if (action.payload) {
          console.error(action.payload)
        } else {
          console.error(action.error.message)
        }
        state.loadingTransportOffers = 'failed'
      })
  },
})

export const { setLoadingTransportOffers } = transportOffersSlice.actions
export const transportOffersReducer = transportOffersSlice.reducer
