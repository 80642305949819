import BookingStatus from '../types/BookingStatus'
import { COUNTRY, COUNTRYCODE } from '../types/Countrys'
import { ReservationStatus } from '../types/ReservationStatus'
import { AdminAccessRole, UserStatus } from '../types/user.types'

export type FilterItem = {
  label: string
  value: string
  callback: string
}

export const transportFilterItems: FilterItem[] = [
  {
    label: BookingStatus.AVAILABLE,
    value: BookingStatus.AVAILABLE,
    callback: BookingStatus.AVAILABLE,
  },
  {
    label: BookingStatus.BOOKED,
    value: BookingStatus.BOOKED,
    callback: BookingStatus.BOOKED,
  },
  {
    label: BookingStatus.EXPIRED,
    value: BookingStatus.EXPIRED,
    callback: BookingStatus.EXPIRED,
  },
  {
    label: BookingStatus.LOCKED,
    value: BookingStatus.LOCKED,
    callback: BookingStatus.LOCKED,
  },
]

export const FreeriderUserFilterItems: FilterItem[] = [
  {
    label: UserStatus.PENDING,
    value: UserStatus.PENDING,
    callback: UserStatus.PENDING,
  },
  {
    label: UserStatus.ACTIVATED,
    value: UserStatus.ACTIVATED,
    callback: UserStatus.ACTIVATED,
  },
  {
    label: UserStatus.WARNED,
    value: UserStatus.WARNED,
    callback: UserStatus.WARNED,
  },
  {
    label: UserStatus.SUSPENDED,
    value: UserStatus.SUSPENDED,
    callback: UserStatus.SUSPENDED,
  },
]

export const adminUserFilterItems: FilterItem[] = [
  {
    label: AdminAccessRole.ALL_ACCESS,
    value: AdminAccessRole.SUPER,
    callback: AdminAccessRole.SUPER,
  },
  {
    label: AdminAccessRole.TRANSPORT,
    value: AdminAccessRole.TRANSPORT,
    callback: AdminAccessRole.TRANSPORT,
  },
]

export const reservationFilterItems: FilterItem[] = [
  {
    label: ReservationStatus.ACTIVE,
    value: ReservationStatus.CREATED,
    callback: ReservationStatus.CREATED,
  },
  {
    label: ReservationStatus.BOOKED_PASSED,
    value: ReservationStatus.PASSED,
    callback: ReservationStatus.PASSED,
  },
  {
    label: ReservationStatus.CANCELLED,
    value: ReservationStatus.CANCELLED,
    callback: ReservationStatus.CANCELLED,
  },
]

export const stationFilterItems: FilterItem[] = [
  { label: COUNTRY.NORWAY, value: COUNTRYCODE.NORWAY, callback: COUNTRYCODE.NORWAY },
  { label: COUNTRY.SWEDEN, value: COUNTRYCODE.SWEDEN, callback: COUNTRYCODE.SWEDEN },
]
