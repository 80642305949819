import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import PageLayout from '../features/layout/PageLayout'
import TableContainer from '../features/table/TableContainer'
import TableHeader from '../features/table/TableHeader'
import { setTableFiltering } from '../features/table/tableSettingsSlice'
import { reservationFilterItems } from '../models/FilterItems'
import { reservationColumns } from '../models/ReservationColumns'
import { RootState, useAppDispatch } from '../store/store'
import { Reservation } from '../types/Reservation'

export default function Reservations() {
  const appDispatch = useAppDispatch()

  const [tableFilter, setTableFilter] = useState(
    useSelector((state: RootState) => state.tableSettings.reservations.filter),
  )

  useEffect(() => {
    appDispatch(setTableFiltering({ tableFilter, tableVariant: 'reservations' }))
  }, [appDispatch, tableFilter])

  return (
    <PageLayout>
      <TableHeader title="Reservations" />
      <TableContainer<Reservation>
        tableColumns={reservationColumns}
        tableVariant="reservations"
        filterItems={reservationFilterItems}
        filterColumn="status"
        filter={tableFilter}
        setTableFilter={setTableFilter}
      />
    </PageLayout>
  )
}
