import { Box } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { useSelector } from 'react-redux'

import LoadingStateMessage from './LoadingStateMessage'
import Table from './Table'
import { setColumnOrder, setPage, setPageSize, setSorting } from './tableSettingsSlice'
import { RootState, useAppDispatch } from '../../store/store'
import FRButton from '../../styles/buttons/Button'
import { Column, TableVariant, WithRowIds } from '../../types/table.types'
import { AlertDialog, setDrawerOpen } from '../ui'

type Props<T> = {
  tableVariant: TableVariant
  tableData: T[]
  tableColumns: TableColumn<T>[]
  isLoading: boolean
  selRows: T[]
  clearRows: boolean
  clickableRows?: boolean
  selectableRows?: boolean
  setClickedRowId?: Dispatch<SetStateAction<number>>
  setClickedRowIamId?: Dispatch<SetStateAction<string>>
  setSelRows: Dispatch<SetStateAction<T[]>>
  clearSelectedRows: () => void
  deleteSelectedRows: () => Promise<void>
}

export default function TableContent<T extends WithRowIds>({
  tableVariant,
  tableData,
  tableColumns,
  isLoading,
  selRows,
  clearRows,
  clickableRows,
  selectableRows,
  setClickedRowId,
  setClickedRowIamId,
  setSelRows,
  clearSelectedRows,
  deleteSelectedRows,
}: Props<T>) {
  const appDispatch = useAppDispatch()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [dataVersion, setDataVersion] = useState(0)

  const { page, pageSize, columnOrder, hiddenColumns, sorting } = useSelector(
    (state: RootState) => state.tableSettings[tableVariant],
  )

  useEffect(() => {
    setDataVersion((prevVersion) => prevVersion + 1)
  }, [tableData, hiddenColumns.length])

  const reOrderedColumns = useMemo(() => {
    if (columnOrder.length === 0) {
      return [...tableColumns]
    }
    const columnOrderMapping: Record<string, number> = {}
    columnOrder.forEach((name: string, index: number) => {
      columnOrderMapping[name] = index
    })
    return [...tableColumns].sort((a, b) => {
      const orderA = columnOrderMapping[a.name as string]
      const orderB = columnOrderMapping[b.name as string]

      if (orderA === undefined || orderB === undefined) {
        throw new Error('Invalid order')
      }
      return orderA - orderB
    })
  }, [tableColumns, columnOrder])

  const handleRowClicked = (clickedRow: T) => {
    if (clickableRows) {
      if (setClickedRowId) {
        setClickedRowId(clickedRow.id)
      }
      if (setClickedRowIamId && clickedRow.iamId) {
        setClickedRowIamId(clickedRow.iamId)
      }
      appDispatch(setDrawerOpen(true))
    }
  }

  const handleRowSelected = ({ selectedRows }: { allSelected: boolean; selectedCount: number; selectedRows: T[] }) => {
    setSelRows(selectedRows)
  }

  const handleColumnOrderChange = (order: TableColumn<T>[]) => {
    const newOrder: string[] = []
    order.map((columnName: Column) => newOrder.push(columnName.name))
    appDispatch(setColumnOrder({ columnOrder: newOrder, tableVariant }))
  }

  return (
    <div>
      {selRows.length > 0 && (
        <Box sx={{ backgroundColor: '#E3F2FD' }} padding={2}>
          <FRButton
            size="small"
            label={`Delete ${selRows.length} ${selRows.length > 1 ? 'items' : 'item'}`}
            onClick={() => setDeleteDialogOpen(true)}
          />{' '}
          <FRButton
            size="small"
            label="Clear"
            onClick={() => {
              clearSelectedRows()
            }}
          />
        </Box>
      )}
      <AlertDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title="Confirm"
        contentText="Delete the selected items?"
        handleConfirmButtonClick={deleteSelectedRows}
      />
      {isLoading || tableData.length < 1 ? (
        <LoadingStateMessage message={isLoading ? 'Loading...' : 'There Are No Records To Display'} />
      ) : (
        <Table<T>
          key={dataVersion}
          data={tableData}
          columns={reOrderedColumns}
          columnsToHide={hiddenColumns}
          selectableRows={selectableRows}
          clearSelectedRows={clearRows}
          defaultSortFieldId={sorting.column.id}
          defaultSortAsc={sorting.order === 'asc'}
          paginationDefaultPage={page}
          paginationPerPage={pageSize}
          paginationTotalRows={tableData.length}
          paginationComponentOptions={{
            selectAllRowsItem: true,
          }}
          onRowClicked={handleRowClicked}
          onSelectedRowsChange={handleRowSelected}
          onChangePage={(newPage) => {
            appDispatch(
              setPage({
                currentPage: newPage,
                tableVariant,
              }),
            )
          }}
          onChangeRowsPerPage={(newPageSize, newPage) => {
            appDispatch(
              setPageSize({
                pageSize: newPageSize,
                tableVariant,
              }),
            )
            appDispatch(
              setPage({
                currentPage: newPage,
                tableVariant,
              }),
            )
          }}
          onSort={(newColumn, newDirection) => {
            appDispatch(
              setSorting({
                sort: { order: newDirection, column: { id: newColumn.id } },
                tableVariant,
              }),
            )
          }}
          onColumnOrderChange={handleColumnOrderChange}
        />
      )}
    </div>
  )
}
