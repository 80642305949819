import {
  styled,
  Paper,
  Divider,
  ToggleButton as FilterButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material'

import React, { useState } from 'react'

const FilterButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,

    '&.MuiButtonBase-root': {
      padding: theme.spacing(0.5, 0.875),
      color: '#45454E',
      '&:hover': {
        backgroundColor: '#D5E3ED',
        color: '#45454E',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff',
        color: '#45454E',
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiTouchRipple-root': {
        color: '#5589ae',
      },
    },
  },
}))

const FilterButtonLabel = styled('span')((props) => ({
  fontWeight: '600',
  fontSize: '12px',
  color: props.color || '#45454E',
}))

export type Button = {
  label: string
  value: string
  callback: Function
}

type ResetFilterButtonBar = {
  label: string
  value: string
  callback: Function
}

interface FilterButtonBarProps extends ToggleButtonGroupProps {
  resetFilterButton: ResetFilterButtonBar
  filterButtons: Button[]
  defaultSelectedFilterButton: string
}

export function FilterButtonBar({
  resetFilterButton,
  filterButtons,
  defaultSelectedFilterButton,
}: Readonly<FilterButtonBarProps>) {
  const [selectedFilterButtonState, setSelectedFilterButtonState] = useState(defaultSelectedFilterButton)

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'inline-flex',
        flexWrap: 'wrap',
        width: 'auto',
        backgroundColor: '#E8EFF4',
      }}
    >
      {resetFilterButton && (
        <>
          <FilterButtonGroup value={selectedFilterButtonState} exclusive>
            <FilterButton
              value={resetFilterButton.value}
              onClick={(event) => {
                event.preventDefault()
                setSelectedFilterButtonState(resetFilterButton.value)
                resetFilterButton.callback(resetFilterButton.value)
              }}
              sx={{ textTransform: 'capitalize' }}
            >
              <FilterButtonLabel>{resetFilterButton.label.toLowerCase()}</FilterButtonLabel>
            </FilterButton>
          </FilterButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0, my: 1.3 }} />
        </>
      )}

      {filterButtons && (
        <FilterButtonGroup value={selectedFilterButtonState} exclusive>
          {filterButtons.map((filterButton) => (
            <FilterButton
              key={filterButton.value}
              value={filterButton.value}
              onClick={(event) => {
                event.preventDefault()
                setSelectedFilterButtonState(filterButton.value)
                filterButton.callback(filterButton.value)
              }}
              sx={{ textTransform: 'capitalize' }}
            >
              <FilterButtonLabel>{filterButton.label.toLowerCase()}</FilterButtonLabel>
            </FilterButton>
          ))}
        </FilterButtonGroup>
      )}
    </Paper>
  )
}
