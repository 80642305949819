import { Box } from '@mui/material'
import { useCallback } from 'react'

import ColumnChoser from './ColumnChoser'
import { Button, FilterButtonBar } from './FilterButtonBar'
import { FilterItem } from '../../../models/FilterItems'
import { useAppDispatch } from '../../../store/store'
import { TableVariant } from '../../../types/table.types'
import { setUsersHiddenColumns } from '../../users/usersSlice'
import { setHiddenColumns } from '../tableSettingsSlice'

type Props = {
  tableVariant: TableVariant | 'freeriderUsers'
  columNames: string[]
  filterItems: FilterItem[]
  filter: string
  hiddenColumns: string[]
  onFilterChange: (value: string) => void
}

export default function TableToolBar({
  tableVariant,
  columNames,
  filterItems,
  filter,
  hiddenColumns,
  onFilterChange,
}: Props) {
  const appDispatch = useAppDispatch()

  const filterButtons: Button[] = filterItems.map((filterItem) => ({
    label: filterItem.label,
    value: filterItem.value,
    callback: () => {
      onFilterChange(filterItem.callback.toString())
    },
  }))

  const getHiddenColumns = useCallback(
    (unchecked: string[]) => {
      appDispatch(
        tableVariant === 'freeriderUsers'
          ? setUsersHiddenColumns(unchecked)
          : setHiddenColumns({ hiddenColumns: unchecked, tableVariant }),
      )
    },
    [tableVariant, appDispatch],
  )

  return (
    <Box display="inline-flex">
      <FilterButtonBar
        defaultSelectedFilterButton={filter}
        resetFilterButton={{
          label: 'show all',
          value: '',
          callback: () => {
            onFilterChange('')
          },
        }}
        filterButtons={filterButtons}
      />
      <ColumnChoser
        resetButtonLabel="Reset to default"
        buttonLabel="Columns"
        columnNames={columNames}
        getUnCheckedList={getHiddenColumns}
        uncheckedColumns={hiddenColumns}
      />
    </Box>
  )
}
