import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getAllReservations } from './api/Reservations'
import { Reservation } from '../../types/Reservation'

export interface ReservationState {
  reservations: Reservation[]
  loadingReservations: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState: ReservationState = { reservations: [], loadingReservations: 'idle' }

export const fetchAllReservations = createAsyncThunk('reservations/fetchAll', async () => {
  try {
    const response = await getAllReservations()
    return response
  } catch (error) {
    throw Error('failed to get reservations')
  }
})

const reservationSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReservations.pending, (state) => {
        state.loadingReservations = 'pending'
      })
      .addCase(fetchAllReservations.fulfilled, (state, action) => {
        state.reservations = action.payload
        state.loadingReservations = 'succeeded'
      })
      .addCase(fetchAllReservations.rejected, (state, action) => {
        if (action.payload) {
          console.error(action.payload)
        } else {
          console.error(action.error.message)
        }
        state.loadingReservations = 'failed'
      })
  },
})

export default reservationSlice.reducer
