import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getAdmins } from '../../api/user'

import { User } from '../../types/user.types'

export interface AdminsState {
  admins: User[]
  loadingAdmins: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState: AdminsState = { admins: [], loadingAdmins: 'idle' }

export const fetchAllAdmins = createAsyncThunk('admins/fetchAll', async () => {
  try {
    const response = await getAdmins()
    return response
  } catch (error) {
    throw Error('failed to get admins')
  }
})

const adminUsersSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setLoadingAdmins: (state, action: PayloadAction<'idle' | 'pending' | 'succeeded' | 'failed'>) => {
      state.loadingAdmins = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAdmins.pending, (state) => {
        state.loadingAdmins = 'pending'
      })
      .addCase(fetchAllAdmins.fulfilled, (state, action) => {
        state.admins = action.payload
        state.loadingAdmins = 'succeeded'
      })
      .addCase(fetchAllAdmins.rejected, (state, action) => {
        if (action.payload) {
          console.error(action.payload)
        } else {
          console.error(action.error.message)
        }
        state.loadingAdmins = 'failed'
      })
  },
})
export const { setLoadingAdmins } = adminUsersSlice.actions
export default adminUsersSlice.reducer
