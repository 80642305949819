import axios from 'axios'

import { Reservation, ReservationGroups } from '../../../types/Reservation'
import { remapReservationData } from '../../../utils'

const reservationsEndpoint = process.env.REACT_APP_ENDPOINT_RESERVATIONS

export const getAllReservations = async (): Promise<Reservation[]> => {
  try {
    const response = await axios.get<ReservationGroups>(reservationsEndpoint)
    const remapedData = remapReservationData(response.data)
    return remapedData
  } catch (error) {
    return []
  }
}

export const cancelReservationById = async (reservationId: string): Promise<boolean> => {
  try {
    await axios.delete(`${reservationsEndpoint}${reservationId}`)
    return true
  } catch (error) {
    return false
  }
}
